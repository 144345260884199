import { toggleHamburger } from './toggleHamburger';
import { studioSlider } from './sliders';
import { accordion } from './accordion';
import { tabMenu } from './tabMenu';
toggleHamburger('js-hamburger', 'js-spmenu', 1024);
accordion();
tabMenu();

const topNewsUpperSliderElement = document.querySelector('.js-top-news-upper');
const topNewsLowerSliderElement = document.querySelector('.js-top-news-lower');
const topStudioSliderElement = document.querySelector('.js-top-studio');
const studioLowerSlider01Element = document.querySelector(
  '.js-studio-lower-01'
);
const studioLowerSlider02Element = document.querySelector(
  '.js-studio-lower-02'
);
const studioLowerSlider03Element = document.querySelector(
  '.js-studio-lower-03'
);

if (topNewsUpperSliderElement) {
  const topNewsUpperSlider = new Splide(topNewsUpperSliderElement, {
    autoWidth: true,
    type: 'loop',
    pagination: false,
    rewind: true,
    arrows: false,
    focus: 'center',
    gap: '60px',
    autoScroll: {
      pauseOnHover: false,
      speed: 1,
    },
    breakpoints: {
      640: {
        gap: '30px',
      },
    },
    // }).mount();
  }).mount(window.splide.Extensions);
}

if (topNewsLowerSliderElement) {
  const topNewsLowerSlider = new Splide(topNewsLowerSliderElement, {
    autoWidth: true,
    type: 'loop',
    pagination: false,
    arrows: false,
    rewind: true,
    focus: 'center',
    start: 6,
    gap: '60px',
    direction: 'rtl',
    autoScroll: {
      pauseOnHover: false,
      speed: 1,
    },
    breakpoints: {
      640: {
        gap: '30px',
      },
    },
    // }).mount();
  }).mount(window.splide.Extensions);

}

if (topStudioSliderElement) {
  const topStudioSlider = new Splide(topStudioSliderElement, {
    type: 'loop',
    pagination: false,
    rewind: true,
    gap: '60px',
    autoplay: true,
  }).mount();

}

if (studioLowerSlider01Element) {
  const studioLowerSlider01 = new Splide(studioLowerSlider01Element, {
    type: 'fade',
    pagination: false,
    rewind: true,
    gap: '60px',
    autoplay: true,
  }).mount();
}

if (studioLowerSlider02Element) {
  const studioLowerSlider02 = new Splide(studioLowerSlider02Element, {
    type: 'fade',
    pagination: false,
    rewind: true,
    gap: '60px',
    autoplay: true,
  }).mount();
}

if (studioLowerSlider03Element) {
  const studioLowerSlider03 = new Splide(studioLowerSlider03Element, {
    type: 'fade',
    pagination: false,
    rewind: true,
    gap: '60px',
    autoplay: true,
  }).mount();
}

document.addEventListener('DOMContentLoaded', function () {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('_active');
        } else {
          // entry.target.classList.remove("_active");
        }
      });
    },
    {
      root: null,
      rootMargin: '0px 0px -15% 0px',
      threshold: 0,
    }
  );

  document.querySelectorAll('.js-animation').forEach((element) => {
    observer.observe(element);

    // ページ読み込み時に、要素がビューポート内にあるかを手動でチェック
    if (
      element.getBoundingClientRect().top <= window.innerHeight &&
      element.getBoundingClientRect().bottom >= 0
    ) {
      element.classList.add('_active');
    }
  });
});

// Swiperインスタンスを保持する変数を定義
let topInstructorSlider;

// Swiperの初期化を行う関数
function initializeSwiper() {
  // 既存のSwiperインスタンスがあれば破棄する
  if (topInstructorSlider) {
    topInstructorSlider.destroy(true, true);
  }

  // 新たにSwiperインスタンスを生成
  topInstructorSlider = new Swiper('.js-top-instructor.swiper', {
    slidesPerView: 2,
    grid: {
      rows: 2,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
}

// 初期化関数を呼び出してSwiperを設定

if (document.querySelector('.js-top-instructor')) {
  initializeSwiper();
  window.addEventListener('resize', initializeSwiper);
}

const tl = gsap.timeline();

const topMvMessageUpper = document.querySelector(
  '.js-gsap-top-mv-message-upper'
);
const topMvMessageLower = document.querySelector(
  '.js-gsap-top-mv-message-lower'
);

tl.to('.char', {
  y: 0 /*テキストのY軸の操作*/,
  stagger: 0.04 /*テキスト間の遅延時間*/,
  delay: 0.4 /*アニメーションのスタートまでの遅延時間*/,
  duration: 0.3 /*アニメーションの時間*/,
  ease: 'expo.out' /*イージングの設定*/,
})
  .fromTo(
    topMvMessageUpper,
    {
      y: '100%' /*テキストのY軸の操作*/,
    },
    {
      y: 0 /*テキストのY軸の操作*/,
      duration: 0.3 /*アニメーションの時間*/,
      ease: 'power3.inOut', //イージングの指定
    }
  )
  .fromTo(
    topMvMessageLower,
    {
      y: '100%' /*テキストのY軸の操作*/,
    },
    {
      y: 0 /*テキストのY軸の操作*/,
      delay: 0,
      duration: 0.7 /*アニメーションの時間*/,
      ease: 'power3.inOut', //イージングの指定
    },
    '-=0.2'
  );
