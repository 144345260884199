export const tabMenu = () => {
  document.addEventListener('DOMContentLoaded', () => {
    let tab = document.querySelectorAll('[role="tab"]'),
      tabpanel = document.querySelectorAll('[role="tabpanel"]');

    if (tab.length === 0 || tabpanel.length === 0) return;
    tabpanel.forEach((panel) => (panel.style.display = 'none'));
    tab[0].classList.add('_is-active');
    tabpanel[0].style.display = 'block';

    tab.forEach((t) => {
      t.addEventListener('click', function () {
        let select = this.getAttribute('aria-selected'),
          id = this.getAttribute('aria-controls'),
          id_body = document.querySelector('#' + id);

        if (select === 'true') {
          return false;
        }

        tabpanel.forEach((panel) => {
          panel.setAttribute('aria-hidden', true);
          panel.style.display = 'none';
        });

        id_body.setAttribute('aria-hidden', false);
        id_body.style.display = 'block';

        tab.forEach((t) => {
          t.setAttribute('aria-selected', false);
          t.classList.remove('_is-active');
        });

        this.classList.add('_is-active');
        this.setAttribute('aria-selected', true);
      });
    });
  });
};
